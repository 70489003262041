<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    source: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
    }),
    account() {
      return this.getAccount(this.accountId);
    },
    dashboardPath() {
      return (
        'https://' +
        this.account.workspace_subdomain +
        '.' +
        window.chatwootConfig.baseURL +
        '/account/workspaces/' +
        this.account.workspace_id
      );
    },
  },
};
</script>

<template>
  <div class="w-8 h-8">
    <router-link :to="dashboardPath" replace>
      <img :src="source" :alt="name" />
    </router-link>
  </div>
</template>
